// 
// EposPrinter ( 0.8.0.0 )
// 
import _ from 'lodash'
import { currentTime } from '../../shared/core/ProcessSupport';

class EposPrinter {

  constructor({ ip, port, deviceId, ui: { printerLogs } }) {
    this.core = {}
    this.ui = {}
    this.setting = {}
    this.status = {}
    
    this.setting.ip = ip
    this.setting.port = port
    this.setting.deviceId = deviceId
    this.ui.printerLogs = printerLogs

    this.log('★ 連線印表機中 ⋯')

    this.core.device = new epson.ePOSDevice()
    this.core.printer = null
  }

  start() {
    this.core.device.connect(
      this.setting.ip,
      this.setting.port,
      data => this.deviceConnect(data)
    )
  }

  createPrinterDevice(deviceObject, retCode) {
    if (retCode === 'OK') {
      this.log("⋯ 確認可列印")
      this.setPrinterEvent(deviceObject)
    } else {
      if (retCode === 'DEVICE_NOT_FOUND') {
        this.log("✘ 找不到裝置，可能有以下原因：")
        this.log("1. 印表機 IP、Port、特殊 ID 設定不正確")
        this.log("2. 印表機沒有網路 或 連線設定有問題")
        this.log("3. 印表機開蓋沒有關閉，導致離線")
        this.log("   或硬體有亮故障燈 ( ERROR ) 需排除")
        this.log("★ 請解決以上問題之後，再重開機器再試一次")
      } else {
        this.log(`✘ 建立失敗，原因「${retCode}」`)
      }
    }
  }

  setPrinterEvent(deviceObject) {
    this.core.printer = deviceObject
    this.core.printer.timeout = 1000 * 60
    this.core.printer.onreceive = r => this.printCompleted(r)
    this.core.printer.oncoveropen = _ => this.log('✘ 印表機已經開蓋！任務可能要重啟')

    if (_.isFunction(window.eposPrinterPrintCommands)) {
      this.log('⋯ 資料處理中')
      window.eposPrinterPrintCommands(this.core.printer)
      this.log('★ 開始列印')
      this.core.printer.send()
    } else {
      this.log('✘ 系統找不到列印指令，無法完成作業')
    }
  }

  callPrintCompletedEvent() {
    try {
      if (_.isFunction(window.eposPrinterPrintCompleted)) {
        window.eposPrinterPrintCompleted()
      }
    } catch (_) { }
  }

  callPrintFailedEvent() {
    try {
      if (_.isFunction(window.eposPrinterPrintFailed)) {
        window.eposPrinterPrintFailed()
      }
    } catch (_) { }
  }

  printCompleted(response) {
    if (response.success) {
      this.log('✔ 列印完成')
      this.callPrintCompletedEvent()
    } else {
      this.log('✘ 列印失敗')
      this.callPrintFailedEvent()
    }
  }

  readyToCreatePrinterDevice() {
    this.log('⋯ 確認狀態')

    this.core.device.createDevice(
      this.setting.deviceId,
      this.core.device.DEVICE_TYPE_PRINTER,
      { 'crypto': false, 'buffer': false },
      (deviceObject, retCode) => {
        this.createPrinterDevice(deviceObject, retCode)
      }
    )
  }

  deviceConnect(data) {
    const acceptConnect = [ 'OK', 'SSL_CONNECT_OK' ].includes(data)

    if (acceptConnect) {
      this.log('⋯ 成功連線')
      this.readyToCreatePrinterDevice()

    } else if (data === 'ERROR_TIMEOUT') {
      this.log("✘ 連線失敗，與機器連線逾時，可能已離線 或 ")
      this.log("1. 印表機設定不正確、沒有網路 或 連線設定有問題")
      this.log("2. 印表機開蓋沒有關閉，導致離線")
      this.log("   或硬體有亮故障燈 ( ERROR ) 需排除")
      this.log("★ 請解決以上問題之後，再重開機器再試一次")

    } else {
      this.log(`✘ 連線失敗，原因「${data}」`)
    }
  }

  log(value) {
    const theTime = currentTime().format('YY/MM/DD HH:mm:ss')
    const log = `<div><span class=\"time\">${theTime}</span><span class=\"message\">${value}</span></div>`

    this.ui.printerLogs.append(log)
  }
}

export default EposPrinter
// 
// EposPrinterPage ( 0.1.0.0 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'
import EposPrinter from '../../shared/core/EposPrinter'

class EposPrinterPage extends GtUiComponent {

  name() {
    return 'EposPrinterPage'
  }

  fetchUi() {
    this.ui.main = $('body.printers')
    this.ui.printerLogs = $('.printer-logs')
  }

  checkUi() {
    this.fetchUi()

    const result =
      this.ui.main.length == 1 &&
      this.ui.printerLogs.length == 1

    return result
  }

  mounted() {
    if (!this.checkUi()) { return false }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    const printer = 
      new EposPrinter({
        ip: window.eposPrinterIp,
        port: window.eposPrinterPort,
        deviceId: window.eposPrinterDeviceId,
        ui: {
          printerLogs: this.ui.printerLogs
        }
      })

    printer.start()
  }
}

export default EposPrinterPage
// 
// ReceiptPrinterCmdConverter ( 1.2.2.0 )
// 
import { parseTime, toInt, currency, currentTime } from '../../shared/core/ProcessSupport';

class ReceiptPrinterCmdConverter {

  constructor({ receiptJson, printer }) {
    this.printer = printer;
    this.companyTaxNumber = null
    this.invoiceNumber = null
    this.invoiceRandomCode = null
    this.invoiceRealCreatedAt = null
    this.buyerTaxNumber = null
    this.salesAmount = null
    this.salesAmountWithoutTax=null    
    this.invoiceType = null
    this.qrCodeLeft = null
    this.qrCodeRight = null  
    this.invoiceCreatedAt = null
    this.invoiceYear = null
    this.invoiceMonth = null
    this.invoiceMingoYear = null
    this.invoiceBeginMonth = null    
    this.portServiceDues = null
    this.orderCode = null
    this.sailingTime = null
    this.orderType = null
    this.chargeTitle = null
    this.charge = null
    this.totalPrice=null
    this.printType=null
    this.luggageTicketNumbers = null;
    this.luggageIds = null;
    this.invoiceCharge = null;
    this.invoiceDriveFee = null;

    this.load( JSON.parse(receiptJson) )
  }

  load(json) {
    // 1. 取得基本資料
    this.companyTaxNumber = json.companyTaxNumber || ''
    this.invoiceNumber = `${json.invoiceNumber}`.replace(/[-]+/g, '')
    this.invoiceRandomCode = json.invoiceRandomCode || ''
    this.invoiceRealCreatedAt = json.invoiceRealCreatedAt || ''
    this.buyerTaxNumber = json.buyerTaxNumber || ''
    this.salesAmount = json.salesAmount || ''
    this.invoiceType = json.invoiceType || ''
    this.qrCodeLeft = `${json.qrCodeLeft || ''}`
    this.qrCodeRight = `${json.qrCodeRight || ''}`
    this.printType=json.printType||'';
    this.orderCode=json.orderCode||'';
    this.sailingTime =json.sailingTime||'';
    this.orderType = json.orderType||'';
    this.chargeTitle=(json.chargeTitle||'').split(',');
    this.charge =(json.charge||'').split(',');
    this.totalPrice=json.totalPrice||'';
    this.invoiceCharge = json.invoiceCharge || 0
    this.invoiceDriveFee = json.invoiceDriveFee || 0

    
    // 2. 處理發票時間資料
    this.invoiceCreatedAt = parseTime(json.invoiceRealCreatedAt)
    this.invoiceYear = toInt(this.invoiceCreatedAt.format('YYYY'))
    this.invoiceMingoYear = this.invoiceYear - 1911;
    this.invoiceMonth = toInt(this.invoiceCreatedAt.format('MM'))

    this.invoiceBeginMonthTime =
      this.invoiceMonth % 2 == 0 ? (
        this.invoiceCreatedAt.date(1).hour(0).minute(0).second(0).subtract(1, 'month')
      ) : (
        this.invoiceCreatedAt.date(1).hour(0).minute(0).second(0)
      )

    this.invoiceEndMonthTime = this.invoiceBeginMonthTime.add(1, 'month')
    this.invoiceBeginMonthStr = this.invoiceBeginMonthTime.format('MM')
    this.invoiceEndMonthStr = this.invoiceEndMonthTime.format('MM')

    // 3. 處理商港服務費收據
    this.portServiceDues = json.portServiceDues || 0

    // 4. 處理行李票
    this.luggageTicketNumbers = (json.luggageTicketNumbers||'').split(',')
    this.luggageIds = (json.luggageIds||'').split(',')
  }

  setLang() {
    this.printer.addTextLang(window.eposPrinterLang)
  }
  /* 電子發票 */
  createDigitalInvoice() {
    if (this.invoiceRandomCode=='') { return }
 
    this.salesAmountWithoutTax=Math.round(this.salesAmount/105.0*100.0)
    const p = this.printer

    const qrCodeLeftWidth = 4
    const qrCodeRightWidth = this.qrCodeRight.length <= 12 ? 6 : 4

    const invoiceBarCode = [
      this.invoiceMingoYear,
      this.invoiceEndMonthStr,
      this.invoiceNumber,
      this.invoiceRandomCode ].join('')

    const invoiceCreatedAtTimeString = 
      this.invoiceCreatedAt.format('YYYY-MM-DD HH:mm:ss')

    this.setLang()
    p.addLogo(48, 48)

    const offset = 55

    p.addPageBegin()
    p.addPageDirection(p.DIRECTION_LEFT_TO_RIGHT)
    p.addPagePosition(0, 0)
    p.addPageArea(20, 0, 416, 600)
    p.addTextSize(2, 2)
    p.addTextVPosition(105-offset)
    p.addTextPosition(0)
    p.addText('電子發票證明聯\n')
    p.addTextSize(2, 2)
    p.addTextPosition(30)
    p.addTextVPosition(165-offset)
    p.addText(`${this.invoiceMingoYear}年${this.invoiceBeginMonthStr}-${this.invoiceEndMonthStr}月\n`)
    p.addTextPosition(30)
    p.addTextVPosition(220-offset)
    p.addText(`${this.invoiceNumber.slice(0,2)}-${this.invoiceNumber.slice(2)}\n`)
    p.addTextSize(1, 1)
    p.addPagePosition(0, 250-offset)

    if ( this.invoiceType =='三聯式' ) {
      p.addText(`${invoiceCreatedAtTimeString}  格式：25\n隨機碼：${this.invoiceRandomCode}      總計：${currency(this.salesAmount)}\n賣方 ${this.companyTaxNumber}   買方 ${this.buyerTaxNumber}\n`)
    } else {
      p.addText(`${invoiceCreatedAtTimeString}\n隨機碼：${this.invoiceRandomCode}      總計：${currency(this.salesAmount)}\n賣方 ${this.companyTaxNumber}\n`)
    }

    p.addPagePosition(17, 368-offset)
    p.addBarcode(invoiceBarCode, p.BARCODE_CODE39, p.HRI_NONE, p.FONT_A, 1, 40)
    p.addPagePosition(15, 385-offset)
    p.addSymbol(this.qrCodeLeft, p.SYMBOL_QRCODE_MODEL_2, p.LEVEL_M, qrCodeLeftWidth, 0, 0)
    p.addPagePosition(210, 385-offset)
    p.addSymbol(this.qrCodeRight, p.SYMBOL_QRCODE_MODEL_2, p.LEVEL_H, qrCodeRightWidth, 0, 0)
    p.addPagePosition(0, 570-offset)
    p.addPageEnd()

    if (this.invoiceType =='二聯式' ) {
      p.addCut(p.CUT_FEED)
    }

    /* 交易明細 */
    p.addTextSize(1, 1)
    p.addTextAlign(p.ALIGN_CENTER)
    p.addText(`  \n`)
    p.addText(`- - - - - - - - - - - - - - - - -\n`)
    p.addLogo(48, 48)
    p.addText(`交  易  明  細\n`)
    p.addText(`=================================\n`)
    p.addTextAlign(p.ALIGN_LEFT)

    if (this.printType=='3') {
      p.addText(`包船費\n`)
    } else if (this.printType=='4') {
      p.addText(`手續費\n`)
    } else if (this.printType=='5') {
      if (this.invoiceCharge > 0) {
        p.addText(`運費\n`)
      }
    } else {
      p.addText(`運費\n`)
    }
  
    p.addTextAlign(p.ALIGN_RIGHT)

    if (this.printType=='2') {
      p.addText(`${currency(this.invoiceCharge)} \n`)
    } else if (this.printType=='5') {
      if (this.invoiceCharge > 0) {
        p.addText(`${currency(this.invoiceCharge)} \n`)
      }    
    } else {
      p.addText(`${currency(this.salesAmount)} \n`)
    }

    if (this.printType=='5') {
      p.addTextAlign(p.ALIGN_LEFT)
      p.addText(`代開費\n`)
      p.addTextAlign(p.ALIGN_RIGHT)
      p.addText(`${currency(this.invoiceDriveFee)} \n`)
    }


    p.addTextAlign(p.ALIGN_CENTER)
    p.addText(`- - - - - - - - - - - - - - - - -\n`)
    p.addTextAlign(p.ALIGN_RIGHT)
    
    if (this.invoiceType =='三聯式') {
      p.addTextSize(1, 1)
      p.addText(`銷售額(應稅)：$ ${currency(this.salesAmountWithoutTax)}\n`)
      p.addText(`稅額：$ ${currency(this.salesAmount - this.salesAmountWithoutTax)}\n`)
    }
    
    p.addTextSize(2, 2)
    p.addText(`總計：\$ ${currency(this.salesAmount)}\n`)
    p.addTextSize(1, 1)
    p.addTextAlign(p.ALIGN_CENTER)
    p.addText(`- - - - - - - - - - - - - - - - -\n`)
    p.addTextAlign(p.ALIGN_LEFT)
    p.addText(`備註：  `)
    p.addText(`訂位代碼- ${this.orderCode} \n`)

    p.addCut(p.CUT_FEED)
  }

  /* 商港服務費 */
  createPortServiceDuesReceipt() {
    if (this.portServiceDues < 1) { return }

    const timeArr=currentTime().format('YYYY-MM-DD').split('-')
    this.setLang();
    const p = this.printer;

    p.addTextSize(1, 1)
    p.addTextAlign(p.ALIGN_CENTER)
    p.addText(`商 港 服 務 費 代 收 收 據\n`)
    p.addText(`=================================\n\n`)
    p.addTextAlign(p.ALIGN_LEFT)
    p.addText(`代收商港服務費\n`)
    p.addTextAlign(p.ALIGN_RIGHT)
    p.addText(`${currency(this.portServiceDues)}TX\n`)
    p.addTextAlign(p.ALIGN_CENTER)
    p.addText(`    \n`)
    p.addText(`- - - - - - - - - - - - - - - - -\n`)
    p.addText(`    \n`)
    p.addTextAlign(p.ALIGN_RIGHT)
    p.addTextSize(2, 2)
    p.addText(`總計：\$ ${currency(this.portServiceDues)}\n`)
    p.addTextSize(1, 1)
    p.addTextAlign(p.ALIGN_CENTER)
    p.addText(`    \n`)
    p.addText(`- - - - - - - - - - - - - - - - -\n`)
    p.addText(`    \n`)
    p.addTextAlign(p.ALIGN_LEFT)
    p.addText(`依據交通部九十年十二月卅一日\n`)
    p.addText(`交航發九十字第○○一○○號令辦理\n`)
    p.addText(`中華民國 ${timeArr[0]-1911}年`)
    p.addText(`${timeArr[1]}月`)
    p.addText(`${timeArr[2]}日`)
    p.addText(`    \n\n\n`)
    p.addTextAlign(p.ALIGN_LEFT)
    p.addCut(p.CUT_FEED)
  }
  /* 訂單明細 */
  createOrderDetail() {
    if (this.chargeTitle.length < 1) { return }

    this.setLang()
    const p = this.printer

    p.addLogo(48, 48)
  
    p.addTextSize(1, 1)
    p.addTextAlign(p.ALIGN_CENTER)
    p.addText(`  \n`)
    p.addText(`訂  單  明  細\n`)
    p.addText(`=================================\n\n`)
    p.addTextAlign(p.ALIGN_LEFT)
    p.addText(`付款時間: ${currentTime().format('YYYY-MM-DD')} \n`)
    p.addText(`訂位代碼: ${this.orderCode} \n`)
    p.addText(`開航時間: ${this.sailingTime} \n`)
    p.addText(`訂單類型: ${this.orderType} \n\n`)
    p.addTextAlign(p.ALIGN_CENTER)
    p.addText(`- - - - - - - - - - - - - - - - -\n\n`)

    for( var i=0;i<this.chargeTitle.length;i++ ) {
      p.addTextAlign(p.ALIGN_LEFT)
      p.addText(`${this.chargeTitle[i]}\n`)
      p.addTextAlign(p.ALIGN_RIGHT)
      p.addText(`${this.charge[i]} TX\n`)
    }

    p.addTextAlign(p.ALIGN_CENTER)
    p.addText(`\n`)
    p.addText(`- - - - - - - - - - - - - - - - -\n`)
    p.addTextAlign(p.ALIGN_RIGHT)
    p.addTextSize(2, 2)
    p.addText(`總計：\$ ${currency(this.totalPrice)}\n`)
    p.addTextSize(1, 1)
    p.addTextAlign(p.ALIGN_CENTER)
    p.addText(`- - - - - - - - - - - - - - - - -\n\n\n`)
    p.addCut(p.CUT_FEED)
  }
  /* 行李條 */
  createLuggageRibbon() {
    if (this.luggageTicketNumbers.length < 1) { return }

    this.setLang();
    const p = this.printer;
    p.addTextSize(1, 1)  
    for ( var i=0;i<this.luggageTicketNumbers.length;i++ ) {
      if (this.luggageTicketNumbers[i].length > 4) {
        for (let j=0; j<5; j++) {
          p.addPageBegin()
          p.addPageArea(20, 0, 416, 600)
          p.addTextSize(1, 1)
          p.addPagePosition(0, 0)         
          p.addPageDirection(p.DIRECTION_TOP_TO_BOTTOM)
          p.addText(`* 航行舒適平穩、讓您擁有一個愉悅的海上逍遙之旅 *\n`)           
          p.addPageDirection(p.DIRECTION_BOTTOM_TO_TOP)
          p.addTextSize(1, 1)
          p.addText(`* 航行舒適平穩、讓您擁有一個愉悅的海上逍遙之旅 *\n`)
         
          if ( this.luggageTicketNumbers.length < 11 ) {
            if ( j%2 == 0 ) {
              p.addText(`    \n\n`)
              p.addText(`                   __/___    \n`)
              p.addText(`             _____/______|    |-|       \n`)
              p.addText(`     _______/_TNC___\\_____\\__/  |__     \n`)
              p.addText(`     \\  ==================== --- /    \n`)
              p.addText(` ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~\n`)
             
              p.addText(`         ${this.luggageTicketNumbers[i]}\n`)
            }
          } else {
            if ( j%2 == 0 ) {
              p.addText(`\n\n\n\n         ${this.luggageTicketNumbers[i]}\n`)
            }
          }
        
          if ( j%2 ==1 ) {
            p.addText('\n\n\n\n\n')
            p.addTextAlign(p.ALIGN_CENTER)
            p.addTextSize(2, 2)
            p.addText(`行李標籤號 : `)
            p.addTextSize(3, 3)
            p.addText(`${this.luggageIds[i]}\n`)
          }  
          p.addPageDirection(p.DIRECTION_LEFT_TO_RIGHT)
          p.addTextSize(1, 1)
          p.addPageEnd()
        }
  
        p.addCut(p.CUT_FEED)
      }
    }
  }

  /* 依照printType決定列印順序 */ 
  printOrder() {

    //  printType ->   1:客運訂單  2:車輛訂單，貨運訂單  3:包船   4:退款訂單  5:含代開車輛訂單  6:補印行李條
    if ( this.printType=="1"||this.printType=="4" ) {
      this.createOrderDetail();
      this.createDigitalInvoice();
      this.createPortServiceDuesReceipt();
      this.createLuggageRibbon();
    } else if ( this.printType=="2" ) {
      this.createOrderDetail();
      this.createDigitalInvoice();
      this.createPortServiceDuesReceipt();
    } else if ( this.printType=="5" ) {
      this.createOrderDetail();
      this.createDigitalInvoice();
      this.createPortServiceDuesReceipt();
    } else if ( this.printType=="6" ) {
      this.createLuggageRibbon();
    } else {
      this.createDigitalInvoice();
    }
    
  }

  formatInvoiceItems(invoiceItemsJson) {
    const newItems = []
    
    const itemsArr = invoiceItemsJson.split(';')

    itemsArr.forEach(invoiceItem => {
      const itemArr = invoiceItem.split(',')
      const itemName = itemArr[0] || ''
      const itemPrice = toInt(itemArr[1])
      const itemQuantity = toInt(itemArr[2])

      newItems.push([ itemName, itemPrice, itemQuantity ])
    })

    return newItems;
  }

}

export default ReceiptPrinterCmdConverter
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

function currency(value){
  const formatter = new Intl.NumberFormat('en-US', { currency: 'TWD' });
  return formatter.format(value);
}

function currentTime() {
  return dayjs()
}

function parseTime(timeString, tz='Asia/Taipei') {
  return dayjs(timeString).tz(tz);
}

function toInt(string, base=10) {
  return parseInt(string, 10);
}

export {
  currentTime,
  parseTime,
  toInt,
  currency
}
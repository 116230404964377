import { GtUi } from '../shared/core/GtUi'

import ReceiptPrinterCmdConverter from './components/ReceiptPrinterCmdConverter'
window.ReceiptPrinterCmdConverter = ReceiptPrinterCmdConverter

import EposPrinterPage from './pages/EposPrinterPage'

GtUi('Epos', {
  route: /\/epos\/printer\//,
  load: {
    components: {
      EposPrinterPage
    }
  }
})